import request from '@/utils/request'

//登录
export function carLista(params) {
  return request({
    url: `/api/getBrandList`,
    method: 'get',
    params
  })
}
//更新密码
export function setPwd(params) {
  return request({
    url: `/api/setPwd`,
    method: 'post',
    params
  })
}
//判断用户是否登录过期
export function isLogin(params) {
  return request({
    url: `/api/isLogin`,
    method: 'post',
    params
  })
}